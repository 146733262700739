@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  overflow: scroll;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #fafafa;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  body {
    font-size: 15px;
  }
}

@media only screen and (max-width: 479px) {
  body {
    font-size: 14px;
  }
}
